.variations {

    .post {
        margin-bottom: 40px;
    }


    h2 {
        max-width: 550px;
    }

    h3 {
        margin-bottom: 20px;
    }

    strong {
        color: $text-color-dark;
        font-weight: 800;
    }

    h4 {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 30px;
    }

    ul {
        li{
            display: flex;
            gap: 9px;
            align-items: center;
            margin-bottom: 10px;

            &::before {
                content: '';
                width: 20px;
                height: 20px;
                mask-repeat: no-repeat;
                background-color: $primary;
                transition: all 0.15s ease-in-out;
                mask-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cg clip-path='url(%23clip0_207_586)'%3e%3cpath d='M13.5817 6.90667L14.7517 8.09417L9.92417 12.85C9.60167 13.1725 9.1775 13.3333 8.75167 13.3333C8.32583 13.3333 7.8975 13.1708 7.57167 12.8458L5.25333 10.5992L6.41417 9.40167L8.74167 11.6575L13.5817 6.90667ZM20 10C20 15.5142 15.5142 20 10 20C4.48583 20 0 15.5142 0 10C0 4.48583 4.48583 0 10 0C15.5142 0 20 4.48583 20 10ZM18.3333 10C18.3333 5.405 14.595 1.66667 10 1.66667C5.405 1.66667 1.66667 5.405 1.66667 10C1.66667 14.595 5.405 18.3333 10 18.3333C14.595 18.3333 18.3333 14.595 18.3333 10Z' fill='%23DDB96F'/%3e%3c/g%3e%3cdefs%3e%3cclipPath id='clip0_207_586'%3e%3crect width='20' height='20' fill='white'/%3e%3c/clipPath%3e%3c/defs%3e%3c/svg%3e ");
            }
        }
    }
}