.banner {

    background-color: $text-color-dark;

    &-content {
        max-width: 712px;
        color: #fff;

        h2 {
            font-size: 20px;
            font-weight: 800;
            line-height: 28px;

            @media (width < 992px) {
                font-size: 18px;
                line-height: 26px;
            }
        }

        p {
            font-size: 16px;
            line-height: 24px;

            strong {
                color: $primary;
                font-weight: 800;
            }
        }
    }
}