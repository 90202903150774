.info-section {
    background-color: $base-color;

    .text-padding {
        padding-right: 140px;
    }

    &-content {
        margin-bottom: 100px;

        @media (width < 992px) {
           margin-bottom: 70px;
        }

        @media (width < 768px) {
            margin-bottom: 35px;
        }

        &:last-child {
            margin-bottom: 0;
        }

        .text-padding-left {
            padding-left: 140px;

            @media (width < 992px) {
                padding-left: 40px;
            }

            @media (width < 768px) {
                padding-left: 0;
            }
        }

        .text-padding-right {
            padding-right: 140px;

            @media (width < 992px) {
                padding-right: 40px;
            }

            @media (width < 768px) {
                padding-right: 0;
            }
        }

        &:hover img,
        &:focus img {
            transform: scale(1.15);
        }

    }


    .info-image {
        height: 456px;
        max-width: 612px;
        overflow: hidden;

        @media (width < 992px) {
           height: 400px;
        }

        @media (width < 768px) {
           height: 300px;
        }

        img {
            height: 100%;
            width: 100%;
            object-position: center;
            object-fit: cover;
            transition: .3s ease-in-out;
        }
    }

    .categories {
        margin-bottom: 120px;

        @media (width < 992px) {
            margin-bottom: 90px;
        }

        @media (width < 768px) {
            margin-bottom: 60px;
        }

        .tag {
            color: $primary;
            border: 1px solid $primary;
            font-size: 14px;
            border-radius: 0;
            padding: 13px 30px;
            font-weight: 600;
            line-height: 24px;
            letter-spacing: 1.4px;
            z-index: 2;
            text-transform: uppercase;
            transition: .3s ease-in-out;

            @media (width < 768px) {
                min-width: 196px;
            }

            &:hover, &.active {
                background-color: $primary;
                color: #fff;
            }
        }
    }


}


