.choose-us {
    background-color: $text-color-dark;
    color: #fff;

    .heading {
        max-width: 536px;

        @media (width < 992px) {
            max-width: 100%;
        }

        p {
            color: $light;
        }
    }

    .values {
        h3 {
            font-size: 20px;
            font-weight: 800;
            line-height: 25.1px;

            @media (width < 768px) {
                font-size: 18px;
                line-height: 23px;
            }
        }

        p {
            color: $light;
            font-size: 16px;
            line-height: 24px;
        }
    }
}