.faq-accordion {
    background-color: $gray;

    .inner-container {
        max-width: 1096px;
        margin: 0 auto;
    }


    .heading {
        h2 {
            max-width: 450px;
        }

        p {
            max-width: 300px;
        }

    }


    .image {
        max-width: 536px;
        height: 642px;
        overflow: hidden;

        @media (width < 992px) {
            max-width: 100%;
            height: 400px;
        }

        @media (width < 768px) {
            max-width: 100%;
            height: 350px;
        }

        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }




    .accordion {

        --bs-accordion-btn-icon-transform: rotate(360deg);
        --bs-accordion-btn-icon: url("data:image/svg+xml;charset=UTF-8,%3csvg width='16' height='10' viewBox='0 0 16 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M0 2.3713L6.34667 8.7093C6.78481 9.14539 7.37782 9.39021 7.996 9.39021C8.61418 9.39021 9.20719 9.14539 9.64533 8.7093L16 2.36397L14.586 0.952637L8.232 7.2973C8.16949 7.35979 8.08472 7.3949 7.99633 7.3949C7.90795 7.3949 7.82318 7.35979 7.76067 7.2973L1.414 0.95997L0 2.3713Z' fill='%23DDB96F'/%3e%3c/svg%3e ");
        --bs-accordion-btn-active-icon: url("data:image/svg+xml;charset=UTF-8,%3csvg width='16' height='10' viewBox='0 0 16 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M16 7.6287L9.65333 1.2907C9.21519 0.854607 8.62217 0.609786 8.004 0.609786C7.38582 0.609786 6.79281 0.854607 6.35467 1.2907L-1.23383e-07 7.63603L1.414 9.04736L7.768 2.7027C7.83051 2.64021 7.91528 2.6051 8.00367 2.6051C8.09205 2.6051 8.17682 2.64021 8.23933 2.7027L14.586 9.04003L16 7.6287Z' fill='%23181819'/%3e%3c/svg%3e ");


        .accordion-item {
            margin-top: 10px;
            border-radius: 0;
            border: 1px solid $border-color;
            background-color: #fff;

            &:has(.accordion-collapse.show) {
                border: 1px solid $primary;
            }


            .accordion-button {
                padding: 25px;
                background-color: #F9F9F9;

                @media (width < 768px) {
                    padding: 20px;
                }

                &.service {
                    h3 {
                        max-width: 980px;
                    }
                }

                h3 {
                    font-size: 18px;
                    font-weight: 800;
                    line-height: 28px;
                    max-width: 536px;
                    margin-bottom: 0;
                }


                &:not(.collapsed) {
                    background-color: #fff;
                    box-shadow: none;
                    color: $text-color-dark;

                }


                &:focus {
                    box-shadow: none;
                }

                &::after {

                    @media (width < 768px) {
                        margin-left: 20px;
                    }
                }
            }

            &:focus-within {
                border: 1px solid $primary;
            }

            &.accordion-collapse.show {
                border: 1px solid $primary;
            }


            .accordion-body {
                color: $secondary;
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
                padding-top: 0;

                @media (width < 768px) {
                    font-size: 16px;
                }

                &.service {
                    p {
                        max-width: 980px;
                    }
                }

                p {
                    margin-bottom: 0;
                    max-width: 536px;
                }

            }
        }
    }
}