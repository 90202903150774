

.btn {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-width: 1px;
    font-size: 16px;
    border-radius: 0;
    padding: 15px 30px;
    font-family: "Mulish", sans-serif;
    font-weight: 800;
    line-height: 24px;
    z-index: 2;
    transition: .3s ease-in-out;


    &-primary, &-header, &-green {
        color: $text-color-dark;
        background: $gradient;
        border-color: transparent;

        &:hover {
            color: $text-color-dark;
            border-color: $hover-color;
            box-shadow: 0 1px 15px rgba($primary, 0.4),
            0 1px 15px rgba($primary, 0.4);
        }
    }

    &-green {
        background: $green;

        &:hover {
            color: #fff;
            border-color: $text-color-dark;
            background: $text-color-dark;
            box-shadow: none;
        }
    }

    &-header {
        padding: 10px 20px;
        gap: 7px;

        @media (width < 992px) {
            padding: 0;
            background: transparent;
            color: $primary;
            font-size: 20px;
            text-decoration: underline;
            text-underline-offset: 4px;
        }

        //&::before {
        //    content: '';
        //    width: 16px;
        //    height: 16px;
        //    mask-repeat: no-repeat;
        //    background-color: currentColor;
        //    transition: all 0.15s ease-in-out;
        //    mask-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M9.33333 11.3333H13.3333V12.6667H9.33333V11.3333ZM9.33333 8.66667V10H10.6667V8.66667H9.33333ZM13.3333 2.66667H5.33333V4.77267C5.81067 4.848 6.258 4.962 6.66667 5.11333V4H12V5.33333H7.178C7.87467 5.672 8.43 6.126 8.80267 6.66667H13.3333V2.66667ZM14 0H4.66667C3.562 0 2.66667 0.895333 2.66667 2V4.77267C3.08933 4.706 3.53333 4.66667 4 4.66667V2C4 1.632 4.29867 1.33333 4.66667 1.33333H14C14.368 1.33333 14.6667 1.632 14.6667 2V14.6667H9.15C8.96467 15.168 8.64333 15.6187 8.20533 16H16V2C16 0.895333 15.1047 0 14 0ZM13.3333 8.66667H12V10H13.3333V8.66667ZM8 8.33333V13.6667C8 14.9967 6.28067 16 4 16C1.71933 16 0 14.9967 0 13.6667V8.33333C0 7.00333 1.71933 6 4 6C6.28067 6 8 7.00333 8 8.33333ZM6.66667 13.6667V12.768C5.96933 13.1233 5.04333 13.3333 4 13.3333C2.95667 13.3333 2.03067 13.1233 1.33333 12.768V13.6667C1.33333 14.0193 2.346 14.6667 4 14.6667C5.654 14.6667 6.66667 14.0193 6.66667 13.6667ZM6.66667 11V10.1013C5.96933 10.4567 5.04333 10.6667 4 10.6667C2.95667 10.6667 2.03067 10.4567 1.33333 10.1013V11C1.33333 11.3527 2.346 12 4 12C5.654 12 6.66667 11.3527 6.66667 11ZM6.66667 8.33333C6.66667 7.98067 5.654 7.33333 4 7.33333C2.346 7.33333 1.33333 7.98067 1.33333 8.33333C1.33333 8.686 2.346 9.33333 4 9.33333C5.654 9.33333 6.66667 8.686 6.66667 8.33333Z' fill='%23181819'/%3e%3c/svg%3e ");
        //
        //    @media (width < 992px) {
        //        content: none;
        //    }
        //}

        &::after {
            @media (width < 992px) {
                content: none;
            }
        }

        &:hover {
            @media (width < 992px) {
                color: $primary;
                border-color: transparent;
                box-shadow: none;
            }
        }
    }

    &-outline-white {
        color: #fff;
        border: 2px solid rgba(255, 255, 255, 0.20);

        &:hover {
            border: 2px solid $primary;
            color: $text-color-dark;
            background-color: $primary;
        }

    }


    &-outline-dark-sm {
        color: $text-color-dark;
        border: 1px solid $border-light;
        padding: 10px 20px;

        &:hover {
            border: 1px solid transparent;
            color: $text-color-dark;
            background: $gradient;
        }
    }

    &-outline-dark {
        color: $text-color-dark;
        border: 1px solid $border-light;

        &:hover {
            border: 1px solid transparent;
            color: $text-color-dark;
            background: $gradient;
        }
    }

    &-rounded {
        padding: 7px 20px;
        border-radius: 20px;
    }


    &-after {
        &::after {
            content: '';
            width: 16px;
            height: 10px;
            mask-repeat: no-repeat;
            background-color: currentColor;
            transition: all 0.15s ease-in-out;
            mask-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='16' height='10' viewBox='0 0 16 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M15.416 3.5859L12.3587 0.528564L11.416 1.47123L14.2573 4.31323L0 4.33323V5.66656L14.2973 5.64656L11.4153 8.52856L12.358 9.47123L15.416 6.4139C15.7898 6.03824 15.9996 5.52985 15.9996 4.9999C15.9996 4.46995 15.7898 3.96156 15.416 3.5859Z' fill='%23fff'/%3e%3c/svg%3e ");
        }
    }

    &-before {
        &::before {
            content: '';
            width: 16px;
            height: 10px;
            mask-repeat: no-repeat;
            background-color: currentColor;
            transition: all 0.15s ease-in-out;
            transform: rotate(180deg);
            mask-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='16' height='10' viewBox='0 0 16 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M15.416 3.5859L12.3587 0.528564L11.416 1.47123L14.2573 4.31323L0 4.33323V5.66656L14.2973 5.64656L11.4153 8.52856L12.358 9.47123L15.416 6.4139C15.7898 6.03824 15.9996 5.52985 15.9996 4.9999C15.9996 4.46995 15.7898 3.96156 15.416 3.5859Z' fill='%23fff'/%3e%3c/svg%3e ");
        }
    }

    &-down {
        &::after {
            content: '';
            width: 9px;
            height: 16px;
            mask-repeat: no-repeat;
            background-color: currentColor;
            transition: all 0.15s ease-in-out;
            mask-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='10' height='16' viewBox='0 0 10 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M6.41386 15.416L9.47119 12.3587L8.52852 11.416L5.68652 14.2573L5.66652 -1.66307e-07L4.33319 -2.24589e-07L4.35319 14.2973L1.47119 11.4153L0.528524 12.358L3.58586 15.416C3.96152 15.7898 4.46991 15.9996 4.99986 15.9996C5.52981 15.9996 6.0382 15.7898 6.41386 15.416Z' fill='%23181819'/%3e%3c/svg%3e ");
        }
    }

    &-link-primary {
        color: $text-color-dark;
        background: $primary;
        padding: 7px 15px;
        border-radius: 20px;
        justify-content: start;
        font-weight: 700;
        align-self: start;


        &:hover {
            color: $text-color-dark;
            background: #fff;
        }
    }

    &-link-green {
        color: $text-color-dark;
        background: $green;
        padding: 7px 15px;
        border-radius: 20px;
        justify-content: start;
        font-weight: 700;
        align-self: start;


        &:hover {
            color: #fff;
            background: $text-color-dark;
        }
    }
}

.services .btn-outline-dark-sm::after {
    content: '';
    width: 0;
    height: 10px;
    mask-repeat: no-repeat;
    background-color: currentColor;
    mask-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='16' height='10' viewBox='0 0 16 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M15.416 3.5859L12.3587 0.528564L11.416 1.47123L14.2573 4.31323L0 4.33323V5.66656L14.2973 5.64656L11.4153 8.52856L12.358 9.47123L15.416 6.4139C15.7898 6.03824 15.9996 5.52985 15.9996 4.9999C15.9996 4.46995 15.7898 3.96156 15.416 3.5859Z' fill='%23fff'/%3e%3c/svg%3e ");
    transition: width 0.3s ease-in-out;
}

.services .btn-outline-dark-sm:hover::after {
    width: 16px;
}

.services .btn-link-primary, .btn-link-green {
    margin-bottom: 15px;
}


.dropdown-item {
    .btn-link-primary,
    .btn-link-green {
        font-size: 14px;
        margin-bottom: 10px;
        padding: 5px 13px;
    }
}

