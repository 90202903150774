
.services-swiper {


    .swiper {
        padding: 20px 0 20px;

        @media (width < 992px) {
            padding: 20px 0 50px;
        }

        .swiper-slide {
            height: auto;

            .services-card {

                @media (width < 992px) {
                    margin-right: 40px;
                }
            }
        }


        .swiper-pagination {

            @media (width > 992px) {
                display: none;
            }

            .swiper-pagination-bullet {
                background: $primary;
            }
        }

        .swiper-pagination-bullet-active {
            background: $primary;
        }
    }

}