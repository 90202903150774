.target-group {

    h2 {
        margin-bottom: 50px;
    }

    .grid-container {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        gap: 20px;
    }

    .grid-item {
        text-align: center;
        padding: 10px;

        h3 {
            color: $text-color-dark;
            font-size: 16px;
            font-weight: 700;
            line-height: 24px;
        }

        img {
            height: 52px;
            width: auto;
        }
    }


    @media (max-width: 1200px) {
        .grid-container {
            grid-template-columns: repeat(4, 1fr);
        }
    }

    @media (max-width: 992px) {
        .grid-container {
            grid-template-columns: repeat(3, 1fr);
        }
    }

    @media (max-width: 768px) {
        .grid-container {
            grid-template-columns: repeat(2, 1fr);
        }
    }
}