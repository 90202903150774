
.title {
    font-size: 44px;
    font-weight: 800;
    line-height: 55.22px;
    color: $text-color-dark;
    margin-bottom: 20px;

    @media (width < 992px) {
        font-size: 33px;
        line-height: 45px;
    }

    @media (width < 768px) {
        font-size: 24px;
        line-height: 33px;
        margin-bottom: 15px;
    }
}

.title-sm {
    color: $text-color-dark;
    font-size: 32px;
    font-weight: 800;
    line-height: 40.16px;
    margin-bottom: 30px;

    @media (width < 992px) {
        font-size: 25px;
        line-height: 32px;
        margin-bottom: 25px;
    }

    @media (width < 768px) {
        font-size: 22px;
        line-height: 28px;
        margin-bottom: 15px;
    }
}

.description {
    font-size: 18px;
    font-weight: 500;
    line-height: 28px;

    @media (width < 768px) {
        font-size: 16px;
        line-height: 26px;
    }
}


