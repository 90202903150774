.career-detail {

    .heading {
        padding-bottom: 100px;
        margin-bottom: 100px;
        border-bottom: 1px solid $border-color;

        @media (width < 992px) {
            padding-bottom: 70px;
            margin-bottom: 70px;
        }

        @media (width < 768px) {
            padding-bottom: 50px;
            margin-bottom: 50px;
        }
    }

    strong {
        color: $text-color-dark;
    }

    .main-text {
        color: $text-color-dark;
        font-size: 24px;
        font-weight: 800;
        line-height: 36px;

        @media (width < 992px) {
           font-size: 22px;
            line-height: 34px;
            margin-bottom: 50px;
        }

        @media (width < 768px) {
            font-size: 20px;
            line-height: 32px;
        }

        p {
            max-width: 536px;

            @media (width < 992px) {
                max-width: 100%;
            }
        }
    }

    .content {
        max-width: 536px;

        @media (width < 992px) {
            max-width: 100%;
            margin-bottom: 50px;
        }
    }

    .career-card {

        h3 {
            color: $text-color-dark;
            font-size: 20px;
            font-weight: 800;
            line-height: 25.1px;

            @media (width < 768px) {
                font-size: 18px;
                line-height: 23px;
            }
        }

        p {
            font-size: 16px;
            line-height: 24px;
        }
    }

}