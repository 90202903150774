.about {
    background-color: $text-color-dark;
    position: relative;
    border-bottom: 1px solid $dark;
    overflow: hidden;

    &-image {
        position: absolute;
        top: 0;
        right: 0;

        @media (width < 992px) {
            width: 600px;
            height: auto;
            opacity: 0.6;
        }

        @media (width < 768px) {
            width: 350px;
            height: auto;
        }
    }

    .subtitle {
        color: $primary;
        text-transform: uppercase;
        font-size: 16px;
        font-weight: 800;
        line-height: 20.08px;
    }

    h2 {
        color: #fff;
        font-size: 32px;
        font-weight: 800;
        line-height: 40.16px;

        @media (width < 992px) {
            font-size: 25px;
            line-height: 32px;
        }

        @media (width < 768px) {
            font-size: 23px;
            line-height: 30px;
        }

        strong {
            color: $primary;
        }
    }

    .description {
        color: $light;
    }

    .inner-container {
        max-width: 760px;
        z-index: 2;
        position: sticky;
    }
}