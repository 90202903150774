.about-detail {

    .statistics {
        padding-bottom: 70px;
        border-bottom: 1px solid $border-color;
        margin-bottom: 70px;

        @media (width < 768px) {
            padding-bottom: 50px;
            margin-bottom: 50px;
        }

        span {
            color: $primary;
            font-size: 56px;
            font-weight: 800;
            line-height: 70.28px;

            @media (width < 992px) {
                font-size: 35px;
                line-height: 50px;
            }

            @media (width < 768px) {
                font-size: 30px;
                line-height: 44px;
            }
        }
    }

    h2 {
        max-width: 536px;

        @media (width < 992px) {
            max-width: 100%;
        }

        strong {
            color: $primary;
        }
    }

}