.job {

    h2 {
        max-width: 536px;
    }

    h3 {
      font-weight: 800;
    }

    &-card-detail {
        margin-top: -50px;
        border: 1px solid $border-color;
        background-color: #fff;
        margin-bottom: 100px;
        overflow: hidden;

        @media (width < 768px) {
            margin-bottom: 70px;
        }

        @media (width < 768px) {
            margin-top: 0;
            margin-bottom: 50px;
        }

        &-item {
            padding: 30px 0 30px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            position: relative;

            @media (width < 768px) {
                padding: 25px 0 25px;
            }

            p {

                font-size: 21px;
                font-weight: 500;
                line-height: 26.36px;
                margin-top: 10px;

                @media (width < 768px) {
                    font-size: 18px;
                    line-height: 23px;
                }

                strong {
                    color: $text-color-dark;
                }
            }

            span {
                color: $text-color-dark;
                font-size: 14px;
                font-weight: 800;
                line-height: 17.57px;
                text-transform: uppercase;

            }
        }

        &-item:not(:last-child)::after {
            content: '';
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 0;
            width: 1px;
            height: 56px;
            background-color: $border-color;


            @media (width < 768px) {
                height: 1px;
                width: 50%;
                top: 100%;
                left: 0;
                transform: translateX(50%);
            }
        }

    }

    .heading {
        padding-bottom: 100px;
        border-bottom: 1px solid $border-color;

        @media (width < 992px) {
            padding-bottom: 70px;
        }

        @media (width < 768px) {
            padding-bottom: 50px;
        }

        strong {
            color: $text-color-dark;
        }
    }

    .criteria {
        padding: 100px 0 100px;
        border-bottom: 1px solid $border-color;
        margin-bottom: 100px;

        @media (width < 992px) {
            padding: 70px 0 70px;
            margin-bottom: 70px;
        }

        @media (width < 768px) {
            padding: 50px 0 50px;
            margin-bottom: 50px;
        }
    }

    ul {
        li {
            margin-bottom: 8px;
            font-size: 18px;
            font-weight: 500;
            line-height: 28px;
            display: flex;
            gap: 10px;

            @media (width < 768px) {
                font-size: 16px;
                line-height: 26px;
            }

            &::before {
                content: '';
                margin-top: 8px;
                min-width: 12px;
                height: 12px;
                mask-repeat: no-repeat;
                background-color: $primary;
                transition: all 0.15s ease-in-out;
                mask-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='12' height='12' fill='%23DDB96F'/%3e%3c/svg%3e ");
            }
        }
    }

    .contact-info {

        p {
            font-size: 18px;
            max-width: 536px;

            @media (width < 768px) {
                font-size: 16px;
            }
        }

        a.mail {
            color: $text-color-dark;
            font-size: 18px;
            font-weight: 800;
            line-height: 28px;
            text-decoration: underline;

            &:hover {
                color: $primary;
            }
        }
    }
}