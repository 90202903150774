// Typography
$base-font-family:"Mulish", sans-serif;
$base-font-size: 16px;
$base-font-weight: 400;

// Colors
$primary: #DDB96F;
$secondary: #4F4F53;
$dark: #3A3A3B;
$green: #92DE8B;
$base-color: #FBF5EA;
$text-color-dark: rgb(24, 24, 25);
$gray: #F3F3F3;
$light: #D1D1D1;
$border-light: #E8E8E8;
$hover-color: color-mix(in srgb, $primary 10%, white);


$gradient: linear-gradient(90deg, #DDB96F 0%, #F7DBA7 50%, #DDB96F 100%);

$transition: .3s ease-in-out;



