.repair-policy {

    background-color: $gray;


    .repair-card  {
        padding: 35px;
        background-color: #fff;
        height: 100%;
        border: 1px solid $border-light;

        .image {
            @media (width < 768px) {
                width: 100%;
            }
        }

        img {
            height: 52px;
            width: auto;
        }

        h3 {
            color: $text-color-dark;
            font-size: 20px;
            font-weight: 800;
            line-height: 24px;
            margin-bottom: 20px;
        }
    }
}