

a {
    color: inherit;
    text-decoration: none;
    transition: $transition;

    &:hover {
        text-decoration: none;
    }
}

ul {
    margin-bottom: 0;
    padding-left: 0;
    list-style: none;
}

body {
    font-family: $base-font-family;
    font-size: $base-font-size;
    color: $secondary;
    font-weight: $base-font-weight;
}


.container {
    max-width: 1360px;
    margin: 0 auto;
    padding-inline: 20px;
}

p {
    margin-bottom: 0;
}

#top {
    position: fixed;
    right: 20px;
    bottom: 150px;
    border: none;
    height: 2.5rem;
    width: 2.5rem;
    text-align: center;
    border-radius: 2px;
    background-color: $text-color-dark;
    transform: translate3d(0,8rem,0);
    transition: all .3s;
    cursor: pointer;
    z-index: 11;
    opacity: 0;
}

#top:before {
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' viewBox='0 0 20 20'%3e%3cpath fill='white' fill-rule='evenodd' d='M3.293 9.707a1 1 0 0 1 0-1.414l6-6a1 1 0 0 1 1.414 0l6 6a1 1 0 0 1-1.414 1.414L11 5.414V17a1 1 0 1 1-2 0V5.414L4.707 9.707a1 1 0 0 1-1.414 0' clip-rule='evenodd'/%3e%3c/svg%3e");
    content: " ";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 1rem;
    pointer-events: none;
}


