
.intro-sub {
    position: relative;
    display: flex;
    align-items: center;

    &-image, img, video {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: 50% 15%;
    }

    &-image {
        position: absolute;
        top: 0;
        left: 0;
        object-position: top;
        z-index: -1;

        &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(rgba(0,0,0,0.7), rgba(0, 0, 0, 0)),
            rgba(0, 0, 0, 0.3);
            z-index: 2;
        }

    }

    .video {
        position: relative;
    }

    .video-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6));
        pointer-events: none;
    }

    &-content {
        color: #fff;
        margin: 230px 0 150px;

        @media (width < 768px) {
            margin: 180px 0 100px;
        }

        &.margin-lg {
            margin: 230px 0 230px;

            @media (width < 768px) {
                margin: 180px 0 100px;
            }

        }

        &.margin-sm {
            margin: 230px 0 100px;

            @media (width < 992px) {
                margin: 180px 0 120px;
            }
            @media (width < 768px) {
                margin: 180px 0 100px;
            }
        }

        &.width-sm {
            max-width: 650px;
        }


        h1 {
            font-size: 56px;
            font-weight: 800;
            line-height: 70.28px;


            @media (width < 992px) {
                font-size: 40px;
                line-height: 54px;
            }

            @media (width < 768px) {
                font-size: 33px;
                line-height: 44px;
            }
        }

        p {
            font-size: 21px;
            font-weight: 500;
            line-height: 32px;
            margin-bottom: 40px;

            &.description-sm {
                font-size: 18px;
                margin-top: -20px;

                @media (width < 992px) {
                    margin-top: 0;
                }

            }

            @media (width < 768px) {
                font-size: 18px;
                line-height: 29px;
                margin-bottom: 30px;
            }
        }
    }
}

