

.partners {

    h2 {
        max-width: 648px;
    }

    .flex-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 20px;

        @media (width < 768px) {
            display: none;
        }
    }

    .flex-item {
        flex: 0 1 calc(16.66% - 20px);
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 40px 0 40px;

        img {
            filter: grayscale(100%);
            opacity: 0.5;
            max-height: 40px;
            width: auto;
        }
    }


    @media (width < 1200px) {
        .flex-item {
            flex: 0 1 calc(33.33% - 20px);
        }
    }

    @media (width < 768px) {
        .flex-item {
            flex: 0 1 calc(50% - 20px);
        }
    }

    @media (width < 480px) {
        .flex-item {
            flex: 0 1 100%;
        }
    }

    .companies {
        padding: 30px 0 0;
        @media (width > 768px) {
            display: none;
        }
    }
}