.form {
    max-width: 648px;
    padding: 45px;
    border: 1px solid $primary;

    @media (width < 992px) {
        padding: 38px;
    }

    @media (width < 768px) {
        padding: 30px;
    }

    &.insurance-form {
        max-width: 100%;
    }


    label {
        margin-bottom: 7px;
        font-size: 14px;
        font-weight: 800;
        color: $text-color-dark;

        span {
            font-weight: 400;
        }
    }

    &-group {
        margin-bottom: 20px;


        .form-control {
            padding: 16px 14px;
            border-inline-style: none;
            border: 1px solid $border-color;
            border-radius: 0;
            box-shadow: none !important;
            background-color: transparent;

            @media (width < 768px) {
                padding: 12px 10px;
            }

            &.is-invalid {
                background-color: #ff7c7c;

                &.form-select {
                    background-size: 20px;
                }
            }
        }
    }

    .form-check {
        display: flex;
        align-items: center;
        padding: 0;
        color: $secondary;
        font-size: 16px;
        font-weight: 500;

        @media (width < 768px) {
            font-size: 14px;
        }

        a {
            text-decoration: underline;
        }

        input[type="checkbox"] {
            flex-shrink: 0;
            width: 20px;
            height: 20px;
            margin-right: 10px;
            border: 1px solid #DDDDDD;
            border-radius: 0;
            appearance: none;
            cursor: pointer;

            &:checked {
                background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
                background-color: $secondary;
                border-color: $secondary;
            }
        }
    }



    .checkbox-label {
        padding-left: 30px;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        margin-bottom: 0;
    }

    .file-upload-wrapper {
        position: relative;
        display: inline-block;
        padding-inline: 12px;
        margin: 20px 0 47px;

    }

    .file-upload-input {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        width: 100%;
        height: 100%;
        cursor: pointer;
    }

    .file-upload-button {
        font-weight: 800;
        display: inline-block;
        padding: 15px 0 15px;
        width: 100%;
        border: 2px dashed rgba(24, 24, 25, 0.15);
        text-align: center;
        background-color: $gray;
        color: $text-color-dark;
        border-radius: 0;
        cursor: pointer;
    }

    .btn {
        @media (width < 768px) {
            width: 100%;
        }
    }

}