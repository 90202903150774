.calculator {

    h1 {
        text-align: center;
        font-weight: bold;
        margin-bottom: 50px;
        font-size: 56px;
        color: #000;

        @media(max-width: 500px) {
            font-size: 36px;
        }
    }

    .insurance-form {
        padding: 40px;
        border: 1px solid $border-color;

        .form-group {
            height: 95px;
            margin-bottom: 1rem;
        }
    }

    .form-select {
        --bs-form-select-bg-img: url("data:image/svg+xml;charset=UTF-8,%3csvg width='12' height='8' viewBox='0 0 12 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M0 2.02851L4.76 6.78201C5.08861 7.10908 5.53337 7.29269 5.997 7.29269C6.46063 7.29269 6.90539 7.10908 7.234 6.78201L12 2.02301L10.9395 0.964508L6.174 5.72301C6.12712 5.76988 6.06354 5.7962 5.99725 5.7962C5.93096 5.7962 5.86738 5.76988 5.8205 5.72301L1.0605 0.970008L0 2.02851Z' fill='%23DDB96F'/%3e%3c/svg%3e ");
        background-position: right 1rem center;
        background-size: 12px 8px;
    }

    .form-control:focus {
        background: #DDB96F08;
        border: 2px solid $primary;
    }

    .custom-checkbox {
        position: relative;
        display: inline-flex;
        align-items: center;
        width: 100%;
        height: 58px;
        border: 1px solid $border-color;
        padding-left: 15px;
        background-color: #fff;
        cursor: pointer;

        &:has(input[type="checkbox"]:checked),
        &:has(input[type="radio"]:checked){
            border-color: $primary;
            border-width: 2px;
        }
    }

    .custom-checkbox input[type="checkbox"],
    .custom-checkbox input[type="radio"] {
        position: absolute;
        cursor: pointer;
        height: 20px;
        width: 20px;
        border: 1px solid #DDDDDD;
        border-radius: 50%;
        appearance: none;


        &:checked {
            background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' viewBox='0 0 24 24'%3e%3cpath fill='white' d='M18.7 7.2c-.4-.4-1-.4-1.4 0l-7.5 7.5l-3.1-3.1c-.4-.4-1-.4-1.4 0c-.4.4-.4 1 0 1.4l3.8 3.8c.2.2.4.3.7.3c.3 0 .5-.1.7-.3l8.2-8.2c.4-.4.4-1 0-1.4'/%3e%3c/svg%3e");
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            background-color: $primary;
            border-color: $primary;

        }
    }

    label.checkbox-label {
        cursor: pointer;
    }

    .custom-checkbox input[type="checkbox"]:checked + label.checkbox-label,
    .custom-checkbox input[type="radio"]:checked + label.checkbox-label {
        border-color: $primary;
    }


    .options-list {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 10px;

        @media(max-width: 991px) {
            flex-direction: row;
            flex-wrap: wrap;

            .vertical-line {
                display: none;
            }

            .frequency {
                align-items: center;
            }
        }
    }

    .option-card {
        display: flex;
        justify-content: space-around;
        align-items: center;
        gap: 20px;
        width: 100%;
        border: 1px solid $border-color;
        margin-bottom: 10px;
        padding: 20px;
        cursor: pointer;

        @media(max-width: 991px) {
            flex-direction: column;
            width: 300px
        }

        @media(max-width: 500px) {
            width: 100%;
        }

        &:has(input[type="radio"]:checked) {
            background-color: rgba($primary, 3%);
            border-width: 2px;
            border-color: $primary;
        }

        .image {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 145px;
            height: 65px;
            padding: 20px;
            border: 1px solid $border-color;

            img {
                object-fit: cover;
                width: 100%;
            }
        }

        .vertical-line {
            width: 1px;
            height: 35px;
            background: $border-light;
        }

        .frequency {
            font-weight: 800;
            display: flex;
            flex-direction: column;
            gap: 7px;
            position: relative;

            .frequency-title {
                text-transform: uppercase;
                font-size: 14px;
            }

            .total {
                font-size: 18px;
                font-weight: 900;
            }

            .frequency-details {
                display: flex;
                gap: 5px;

                .price-partial {
                    font-weight: normal;
                }
            }
        }

        .option-checkbox {
            display: inline-flex;
            gap: 10px;
            align-items: center;
            font-weight: 800;

            input[type="radio"] {
                cursor: pointer;
                height: 20px;
                width: 20px;
                border: 1px solid #DDDDDD;
                border-radius: 50%;
                appearance: none;

                &:checked {
                    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' viewBox='0 0 24 24'%3e%3cpath fill='white' d='M18.7 7.2c-.4-.4-1-.4-1.4 0l-7.5 7.5l-3.1-3.1c-.4-.4-1-.4-1.4 0c-.4.4-.4 1 0 1.4l3.8 3.8c.2.2.4.3.7.3c.3 0 .5-.1.7-.3l8.2-8.2c.4-.4.4-1 0-1.4'/%3e%3c/svg%3e");
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: contain;
                    background-color: $primary;
                    border-color: $primary;
                }
            }
        }
    }

    .option-checkbox:checked + .option-content {
        border: 2px solid red;
    }

    .option-content {
        text-align: center;
    }

    .option-content img {
        width: 100%;
        height: auto;
        margin-bottom: 10px;
    }

    .option-content span {
        display: block;
    }

    .tabcontent {
        display: none;
    }

    ul.tabs {
        display: flex;
        overflow-x: auto;

        li {
            display: flex;
            flex-grow: 1;
            border: 1px solid $border-color;
            border-bottom: none;

            &:not(:last-child) {
                border-right: none;
            }

            a {
                display: flex;
                width: 100%;
                height: 100%;
                padding: 20px;
                justify-content: center;
                align-items: center;
                font-weight: bold;
                white-space: nowrap;

                .index {
                    margin-right: 10px;
                    border: 2px solid #000;
                    border-radius: 100%;
                    width: 25px;
                    height: 25px;
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                    font-weight: 800;
                    font-size: 14px;
                }

                &.active:not(.done) {
                    background: #000;
                    color: #fff;

                    .index {
                        border-color: #fff;
                    }
                }

                &.done {
                    background: $primary;
                    color: #000;

                    .index {
                        background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' viewBox='0 0 24 24'%3e%3cpath fill='%23DDB96F' d='M18.7 7.2c-.4-.4-1-.4-1.4 0l-7.5 7.5l-3.1-3.1c-.4-.4-1-.4-1.4 0c-.4.4-.4 1 0 1.4l3.8 3.8c.2.2.4.3.7.3c.3 0 .5-.1.7-.3l8.2-8.2c.4-.4.4-1 0-1.4'/%3e%3c/svg%3e");
                        background-repeat: no-repeat;
                        background-position: center;
                        background-size: contain;
                        background-color: #000;
                        color: $primary;

                        .number {
                            display: none;
                        }
                    }
                }
            }
        }
    }

    .form-buttons {
        display: flex;
        justify-content: flex-end;
        gap: 10px;

        .btn-before {
            border: 1px solid $border-color;
            transition: all 0.15s ease-in-out;

            &:hover {
                background-color: #000;
                color: #fff;
            }
        }

        @media(max-width: 500px) {
            flex-direction: column;

            .btn-before {
                order: 2;
            }
        }
    }

}