.scroll-up {
    position: fixed;
    right: 0;
    bottom: 0;

    display: flex;
    align-items: center;
    justify-content: center;
  
    width: 2.5rem;
    height: 2.5rem;
  
    margin: 1rem;
    padding: 0;

    background-color: white;
    border-radius: 50%;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

  
    opacity: 0;
    transform: translateY(4.5rem);
    transition: all 0.3s;
    cursor: pointer;
    z-index: 11;
  
    &.active {
        transform: translateY(-1rem);
        opacity: 1;
    }
  
    &:hover {
        opacity: .8;
    }

    img {
        width: 20px;
        height: 20px;
        transform: rotate(-90deg);
    }
  }