.employment {
    background-color: $gray;

    .job-card {
        background-color: #fff;
        padding: 45px 35px;
        border-bottom: 1px solid $border-color;

        @media (width < 768px) {
            padding: 30px;
        }

        &:last-child {
            border-bottom: none;
        }

        .icon {
            padding: 10px;
            border: 1px solid $border-color;
        }

        h3 {
            color: $text-color-dark;
            font-size: 20px;
            font-weight: 800;
            line-height: 25.1px;


            @media (width < 768px) {
                font-size: 18px;
                line-height: 23px;
            }
        }

        p {
            color: $secondary;
            font-size: 16px;
            font-weight: 500;
            line-height: 20.08px;
            margin-bottom: 7px;
        }

        strong {
            color: $text-color-dark;
        }

    }

}