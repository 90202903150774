.contact-detail {


    strong {
        color: $text-color-dark;
        font-weight: 800;
    }

    &-info {
        margin-bottom: 65px;
    }

    .phone,
    .mail {
        color: $text-color-dark;
        align-items: center;
        display: flex;
        gap: 13px;
        font-size: 28px;
        font-weight: 800;
        line-height: 35.14px;
        margin-bottom: 20px;

        &:hover {
            color: $primary;
        }

        @media (width < 992px) {
            font-size: 25px;
            line-height: 30px;
            justify-content: center;
        }

        @media (width < 768px) {
            font-size: 20px;
            line-height: 27px;
        }

        &::before {
            content: '';
            width: 24px;
            height: 24px;
            mask-repeat: no-repeat;
            background-color: $primary;
            mask-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cg clip-path='url(%23clip0_61_20628)'%3e%3cpath d='M1.71988 3.29012L4.88988 0.120117L11.1499 6.39012L7.73988 9.80012C9.10988 12.8601 11.2299 14.9901 14.1899 16.2701L17.5999 12.8601L23.8799 19.1101L20.7099 22.2801C19.5999 23.3901 18.1099 24.0001 16.4999 24.0001C9.24988 24.0001 -0.000116348 14.7501 -0.000116348 7.50012C-0.000116348 5.89012 0.609882 4.40012 1.71988 3.29012ZM16.4999 22.0001C17.5699 22.0001 18.5699 21.6001 19.2899 20.8701L21.0499 19.1101L17.6099 15.6701L14.6799 18.6001L14.0699 18.3701C10.1099 16.8601 7.25988 14.0201 5.61988 9.93012L5.36988 9.32012L8.29988 6.38012L4.85988 2.94012L3.09988 4.70012C2.36988 5.43012 1.96988 6.42012 1.96988 7.49012C1.96988 13.7201 10.2399 21.9901 16.4699 21.9901L16.4999 22.0001Z' fill='%23DDB96F'/%3e%3c/g%3e%3cdefs%3e%3cclipPath id='clip0_61_20628'%3e%3crect width='24' height='24' fill='white' transform='matrix(-1 0 0 1 24 0)'/%3e%3c/clipPath%3e%3c/defs%3e%3c/svg%3e ");
        }
    }

    .phone {
        &::before {
            mask-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cg clip-path='url(%23clip0_61_20628)'%3e%3cpath d='M1.71988 3.29012L4.88988 0.120117L11.1499 6.39012L7.73988 9.80012C9.10988 12.8601 11.2299 14.9901 14.1899 16.2701L17.5999 12.8601L23.8799 19.1101L20.7099 22.2801C19.5999 23.3901 18.1099 24.0001 16.4999 24.0001C9.24988 24.0001 -0.000116348 14.7501 -0.000116348 7.50012C-0.000116348 5.89012 0.609882 4.40012 1.71988 3.29012ZM16.4999 22.0001C17.5699 22.0001 18.5699 21.6001 19.2899 20.8701L21.0499 19.1101L17.6099 15.6701L14.6799 18.6001L14.0699 18.3701C10.1099 16.8601 7.25988 14.0201 5.61988 9.93012L5.36988 9.32012L8.29988 6.38012L4.85988 2.94012L3.09988 4.70012C2.36988 5.43012 1.96988 6.42012 1.96988 7.49012C1.96988 13.7201 10.2399 21.9901 16.4699 21.9901L16.4999 22.0001Z' fill='%23DDB96F'/%3e%3c/g%3e%3cdefs%3e%3cclipPath id='clip0_61_20628'%3e%3crect width='24' height='24' fill='white' transform='matrix(-1 0 0 1 24 0)'/%3e%3c/clipPath%3e%3c/defs%3e%3c/svg%3e ");
        }
    }

    .mail {
        text-decoration: underline;
        text-underline-offset: 3px;
        &::before {
            mask-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='24' height='22' viewBox='0 0 24 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M21 0H3C2.20435 0 1.44129 0.31607 0.87868 0.87868C0.31607 1.44129 0 2.20435 0 3L0 22H24V3C24 2.20435 23.6839 1.44129 23.1213 0.87868C22.5587 0.31607 21.7956 0 21 0ZM3 2H21C21.2652 2 21.5196 2.10536 21.7071 2.29289C21.8946 2.48043 22 2.73478 22 3V3.667L14.122 11.546C13.5584 12.1073 12.7954 12.4225 12 12.4225C11.2046 12.4225 10.4416 12.1073 9.878 11.546L2 3.667V3C2 2.73478 2.10536 2.48043 2.29289 2.29289C2.48043 2.10536 2.73478 2 3 2ZM2 20V6.5L8.464 12.96C9.40263 13.8963 10.6743 14.422 12 14.422C13.3257 14.422 14.5974 13.8963 15.536 12.96L22 6.5V20H2Z' fill='%23DDB96F'/%3e%3c/svg%3e ");
        }
    }

    h3 {
        text-transform: uppercase;
        font-weight: 800;
        color: $text-color-dark;
        font-size: 14px;
    }

    .contact-info {
        margin-bottom: 70px;

        @media (width < 768px) {
            margin-bottom: 50px;
        }

        p {
            margin-bottom: 8px;
        }

        .address {
            position: relative;
            padding-right: 40px;

            @media (width < 768px) {
                padding-right: 0;
                padding-bottom: 40px;
            }

            p {
                strong {
                    display: flex;
                    gap: 7px;
                    align-items: center;

                    @media (width < 992px) {
                        justify-content: center;
                    }

                    &::before {
                        content: '';
                        width: 16px;
                        height: 16px;
                        mask-repeat: no-repeat;
                        background-color: $primary;
                        mask-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cg clip-path='url(%23clip0_61_26673)'%3e%3cpath d='M7.97205 16.0054L7.50733 15.607C6.86667 15.0706 1.27271 10.2394 1.27271 6.70554C1.27271 3.0056 4.27211 0.00619507 7.97205 0.00619507C11.672 0.00619507 14.6714 3.0056 14.6714 6.70554C14.6714 10.2394 9.07742 15.0706 8.43942 15.6097L7.97205 16.0054ZM7.97205 1.45479C5.07352 1.45807 2.72461 3.80698 2.72133 6.70551C2.72133 8.92557 6.16296 12.4721 7.97205 14.0953C9.78117 12.4714 13.2228 8.92288 13.2228 6.70551C13.2195 3.80698 10.8706 1.4581 7.97205 1.45479Z' fill='%23DDB96F'/%3e%3cpath d='M7.97197 9.36111C6.50534 9.36111 5.31641 8.17218 5.31641 6.70555C5.31641 5.23893 6.50534 4.04999 7.97197 4.04999C9.43859 4.04999 10.6275 5.23893 10.6275 6.70555C10.6275 8.17218 9.43862 9.36111 7.97197 9.36111ZM7.97197 5.37774C7.23866 5.37774 6.64419 5.97221 6.64419 6.70552C6.64419 7.43883 7.23866 8.0333 7.97197 8.0333C8.70528 8.0333 9.29975 7.43883 9.29975 6.70552C9.29975 5.97221 8.70531 5.37774 7.97197 5.37774Z' fill='%23DDB96F'/%3e%3c/g%3e%3cdefs%3e%3cclipPath id='clip0_61_26673'%3e%3crect width='16' height='16' fill='white'/%3e%3c/clipPath%3e%3c/defs%3e%3c/svg%3e ");
                    }
                }
            }

            &::after {
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                width: 1px;
                height: 100%;
                background-color: $border-color;


                @media (width < 768px) {
                    width: 100%;
                    top: 100%;
                    height: 1px;
                }
            }
        }

        .hours {
            display: flex;
            align-items: center;
            gap: 7px;

            @media (width < 992px) {
                justify-content: center;
            }

            &::before {
                content: '';
                width: 16px;
                height: 16px;
                mask-repeat: no-repeat;
                background-color: $primary;
                mask-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M8 16C3.58867 16 0 12.4113 0 8C0 3.58867 3.58867 0 8 0C12.4113 0 16 3.58867 16 8C16 12.4113 12.4113 16 8 16ZM8 1.33333C4.324 1.33333 1.33333 4.324 1.33333 8C1.33333 11.676 4.324 14.6667 8 14.6667C11.676 14.6667 14.6667 11.676 14.6667 8C14.6667 4.324 11.676 1.33333 8 1.33333ZM11.3333 7.33333H8.66667V3.33333H7.33333V8.66667H11.3333V7.33333Z' fill='%23DDB96F'/%3e%3c/svg%3e ");
            }
        }

        .map-link {
            color: $text-color-dark;
            font-size: 16px;
            font-weight: 800;
            line-height: 24px;
            text-decoration: underline;
        }
    }

    iframe {
        max-width: 648px;
        @media (width < 992px) {
            width: 100%;
            margin-bottom: 50px;
        }
    }
}