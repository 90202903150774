.companies {
    padding: 60px 0 60px;

    h3 {
        color: $text-color-dark;
        text-transform: uppercase;
        font-size: 16px;
        font-weight: 800;
        line-height: 28px;
    }

    .swiper-container {
        mask-image: linear-gradient(to right, transparent 0%, black 20% 80%, transparent 100%);

        .swiper-wrapper {
            -webkit-transition-timing-function:linear!important;
            -o-transition-timing-function:linear!important;
            transition-timing-function:linear!important;


            .swiper-slide {
                width: 128px;
                @media (width < 768px) {
                    padding-bottom: 40px;
                }

                img {
                    filter: grayscale(100%);
                    opacity: 0.5;
                    max-height: 40px;
                    width: auto;
                }
            }
        }
        .swiper-pagination {

            @media (width > 768px) {
                display: none;
            }

            .swiper-pagination-bullet {
                background: $secondary;
            }
        }

        .swiper-pagination-bullet-active {
            background: $secondary;
        }
    }
}