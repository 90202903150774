.section {
    padding: 130px 0 130px;

    @media (width < 992px) {
        padding: 100px 0 100px;
    }

    @media (width < 768px) {
        padding: 50px 0 50px;
    }
}


.services {
    padding: 100px 0 0;

    @media (width < 992px) {
        padding: 130px 0 70px;
    }

    @media (width < 768px) {
        padding: 90px 0 50px;
    }
}

.services-swiper {
    @media (width < 768px) {
        padding: 50px 0 50px;
    }
}

.advantages {
    padding: 100px 0 100px;

    @media (width < 992px) {
        padding: 70px 0 70px;
    }

    @media (width < 768px) {
        padding: 50px 0 50px;
    }
}


.about {
    padding: 90px 0 90px;

    @media (width < 992px) {
        padding: 70px 0 70px;
    }

    @media (width < 768px) {
        padding: 50px 0 50px;
    }
}



.footer {
    padding: 100px 0 80px;

    @media (width < 992px) {
        padding: 45px 0 50px;
    }
}

.banner {
    padding: 50px 0 50px;

    @media (width < 992px) {
        padding: 40px 0 40px;
    }
}

.employment {
    padding: 100px 0 100px;

    @media (width < 992px) {
        padding: 70px 0 70px;
    }

    @media (width < 768px) {
        padding: 50px 0 50px;
    }
}

.job {
    padding-bottom: 150px;

    @media (width < 992px) {
        padding-bottom: 70px;
    }

    @media (width < 768px) {
        padding: 50px 0 50px;
    }
}

.choose-us {
    padding: 100px 0 100px;

    @media (width < 992px) {
        padding: 70px 0 70px;
    }

    @media (width < 768px) {
        padding: 50px 0 50px;
    }
}

#form-service {
    padding: 100px 0 70px;

    @media (width < 992px) {
        padding: 70px 0 70px;
    }

    @media (width < 768px) {
        padding: 50px 0 50px;
    }
}

