.header {
    position: fixed;
    width: 100%;
    z-index: 3;

    a.map-link:hover {
        color: $primary;
    }

    &.dark-bg {
        background: $text-color-dark;
        position: static;
    }

    .container {
        max-width: 1640px;
        position: relative;
    }

    .btn-close {
        --bs-btn-close-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e");
        opacity: 1;
        padding: 20px 30px 0 0;
    }

    .navbar,
    .contact-info {
        transition: top 0.3s ease-in-out, background-color 0.3s ease-in-out;
    }

    //.sticky-navbar {
    //    position: fixed;
    //    top: 0;
    //    width: 100%;
    //    background-color: $text-color-dark;
    //}
    //
    //.hidden {
    //    top: -300px;
    //}

    .sticky-contact-info {
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 1000;
        background: $text-color-dark;
    }


    .contact-info {
        color: $light;
        font-size: 14px;
        line-height: 18px;
        font-weight: 500;
        padding: 12px 0 12px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);

        @media (width < 992px) {
            display: none !important;
        }

        .address {
            display: flex;
            gap: 7px;

            a {
                color: $primary;
                text-decoration: underline;
                font-weight: 800;


                &:hover {
                    color: #fff;
                }
            }

            &::before {
                content: '';
                width: 16px;
                height: 16px;
                mask-repeat: no-repeat;
                background-color: $primary;
                mask-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cg clip-path='url(%23clip0_61_26673)'%3e%3cpath d='M7.97205 16.0054L7.50733 15.607C6.86667 15.0706 1.27271 10.2394 1.27271 6.70554C1.27271 3.0056 4.27211 0.00619507 7.97205 0.00619507C11.672 0.00619507 14.6714 3.0056 14.6714 6.70554C14.6714 10.2394 9.07742 15.0706 8.43942 15.6097L7.97205 16.0054ZM7.97205 1.45479C5.07352 1.45807 2.72461 3.80698 2.72133 6.70551C2.72133 8.92557 6.16296 12.4721 7.97205 14.0953C9.78117 12.4714 13.2228 8.92288 13.2228 6.70551C13.2195 3.80698 10.8706 1.4581 7.97205 1.45479Z' fill='%23DDB96F'/%3e%3cpath d='M7.97197 9.36111C6.50534 9.36111 5.31641 8.17218 5.31641 6.70555C5.31641 5.23893 6.50534 4.04999 7.97197 4.04999C9.43859 4.04999 10.6275 5.23893 10.6275 6.70555C10.6275 8.17218 9.43862 9.36111 7.97197 9.36111ZM7.97197 5.37774C7.23866 5.37774 6.64419 5.97221 6.64419 6.70552C6.64419 7.43883 7.23866 8.0333 7.97197 8.0333C8.70528 8.0333 9.29975 7.43883 9.29975 6.70552C9.29975 5.97221 8.70531 5.37774 7.97197 5.37774Z' fill='%23DDB96F'/%3e%3c/g%3e%3cdefs%3e%3cclipPath id='clip0_61_26673'%3e%3crect width='16' height='16' fill='white'/%3e%3c/clipPath%3e%3c/defs%3e%3c/svg%3e ");
            }
        }

        .phone {
            color: #fff;
            font-weight: 800;
            display: flex;
            align-items: center;
            gap: 7px;


            &:hover {
                color: $primary;
            }

            &::before {
                content: '';
                width: 16px;
                height: 16px;
                mask-repeat: no-repeat;
                color: inherit;
                background-color: $primary;
                mask-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cg clip-path='url(%23clip0_61_26681)'%3e%3cpath d='M1.14659 2.19332L3.25992 0.0799866L7.43325 4.25999L5.15992 6.53332C6.07326 8.57332 7.48659 9.99332 9.45992 10.8467L11.7333 8.57332L15.9199 12.74L13.8066 14.8533C13.0666 15.5933 12.0733 16 10.9999 16C6.16659 16 -7.82013e-05 9.83332 -7.82013e-05 4.99999C-7.82013e-05 3.92665 0.406589 2.93332 1.14659 2.19332ZM10.9999 14.6667C11.7133 14.6667 12.3799 14.4 12.8599 13.9133L14.0333 12.74L11.7399 10.4467L9.78659 12.4L9.37992 12.2467C6.73992 11.24 4.83992 9.34665 3.74659 6.61999L3.57992 6.21332L5.53325 4.25332L3.23992 1.95999L2.06659 3.13332C1.57992 3.61999 1.31326 4.27999 1.31326 4.99332C1.31326 9.14665 6.82659 14.66 10.9799 14.66L10.9999 14.6667Z' fill='%23DDB96F'/%3e%3c/g%3e%3cdefs%3e%3cclipPath id='clip0_61_26681'%3e%3crect width='16' height='16' fill='white' transform='matrix(-1 0 0 1 16 0)'/%3e%3c/clipPath%3e%3c/defs%3e%3c/svg%3e ");
            }
        }

        .hours {
            display: flex;
            align-items: center;
            gap: 7px;

            &::before {
                content: '';
                width: 16px;
                height: 16px;
                mask-repeat: no-repeat;
                background-color: $primary;
                mask-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M8 16C3.58867 16 0 12.4113 0 8C0 3.58867 3.58867 0 8 0C12.4113 0 16 3.58867 16 8C16 12.4113 12.4113 16 8 16ZM8 1.33333C4.324 1.33333 1.33333 4.324 1.33333 8C1.33333 11.676 4.324 14.6667 8 14.6667C11.676 14.6667 14.6667 11.676 14.6667 8C14.6667 4.324 11.676 1.33333 8 1.33333ZM11.3333 7.33333H8.66667V3.33333H7.33333V8.66667H11.3333V7.33333Z' fill='%23DDB96F'/%3e%3c/svg%3e ");
            }
        }
    }

    .navbar {
        padding: 20px 0 20px;
        --bs-navbar-toggler-border-color: transparent;
        --bs-navbar-toggler-font-size: 18px;
        --bs-navbar-toggler-focus-width: 0;
        --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.75%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
        --bs-navbar-hover-color: #DDB96F;
        --bs-navbar-active-color: #DDB96F;

        .btn-rounded {
            @media (width > 992px) {
                display: none;
            }
        }

        @media (width < 992px) {
            padding: 0;
        }

        &-toggler {
            padding: 40px 20px 40px 10px;
        }

        .offcanvas {
            --bs-offcanvas-bg: rgb(24, 24, 25);
            --bs-offcanvas-color: #fff
        }

        .offcanvas-body {
            justify-content: flex-end;
            align-items: center;
        }


        .nav-item {

            &-lang {
                font-size: 14px;
                font-weight: 500;
                line-height: 17.57px;
                padding: 13px;
                border: 1px solid transparent;

                &:hover, &:focus {
                    border: 1px solid $primary;
                    color: $primary;
                }

                &.active {
                    color: $primary;
                }
            }

            .nav-link {
                --bs-nav-link-color: #fff;
                --bs-nav-link-font-size: 16px;
                --bs-nav-link-font-weight: 800;
                --bs-navbar-nav-link-padding-x: 15px;


                @media (width < 992px) {
                    --bs-nav-link-padding-y: 18px;
                    --bs-nav-link-font-size: 20px;
                }
                &:hover {
                    color: $primary;
                }
            }

            @media (width < 992px) {
                --bs-nav-link-font-size: 20px;
                --bs-nav-link-padding-y: 12px;
            }
        }
    }


    .dropdown {

        transition: .01s ease;

        @media (width < 992px) {
            display: flex;
            justify-content: center;
            flex-direction: column;
            padding-bottom: 25px;
        }

        .dropdown-menu {
            display: none;
            right: 0;
            background-color: white;
            border-radius: 0;
            z-index: 1;
            min-width: 1280px;
            width: 100%;
            padding: 25px;
            margin-top: 10px;
            box-sizing: border-box;
            border: none;

            @media (width < 992px) {
                display: block;
                background-color: transparent;
                position: static;
                min-width: 100%;
                padding: 0;
            }

            &::before {
                content: '';
                display: block;
                background: transparent;
                height: 10px;
                width: 100%;
                position: absolute;
                top: -10px;
                left: 0;
            }

            .dropdown-content {
                display: flex;
                flex-wrap: wrap;

                @media (width < 992px) {
                    flex-direction: column;
                }

                .dropdown-item {
                    flex: 1 1 calc(25% - 20px);
                    max-width: calc(25% - 20px);
                    display: flex;
                    flex-direction: column;
                    padding: 25px;
                    border-radius: 0;
                    box-sizing: border-box;
                    transition: background-color 0.3s;
                    white-space: wrap;
                    min-width: 290px;

                    @media (width < 992px) {
                        padding: 0;
                        text-align: center;
                        max-width: 100%;
                    }

                    h3 {
                        font-size: 16px;
                        font-weight: 800;
                        line-height: 24px;

                        @media (width < 992px) {
                            color: rgba(221, 185, 111, 0.7);
                            font-size: 17px;
                            margin-bottom: 22px !important;
                        }
                    }

                    .buttons {
                        @media (width < 992px) {
                            display: none;
                        }
                    }

                    p {
                        font-size: 14px;
                        font-weight: 500;
                        color: $secondary;

                        @media (width < 992px) {
                            display: none;
                        }

                    }

                    &:hover {
                        background-color: #F3F3F3;

                        @media (width < 992px) {
                            background-color: $text-color-dark;
                        }
                    }
                }

                .dropdown-item img.icon {
                    height: 40px;
                    width: 40px;

                    @media (width < 992px) {
                        display: none;
                    }
                }
            }
        }
    }

    .dropdown:hover {
        box-shadow: 0 0 0 2000px rgba(0, 0, 0, 0.5);
        transition: .3s ease;

        @media (width < 992px) {
            box-shadow: none;
        }

        .dropdown-menu {
            display: block;
        }
    }


    .dropdown-toggle {
        @media (width < 992px) {
            margin-bottom: 20px !important;
        }
    }

    .dropdown-toggle::after {
        height: 1.5em;
        width: 1.5em;
        mask-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='1.5em' height='1.5em' viewBox='0 0 24 24'%3e%3cpath fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m7 10l5 5l5-5'/%3e%3c/svg%3e");
        background-color: $text-color-dark;
        margin-left: 0;
        vertical-align: -2px;
    }

    .dropdown:hover .dropdown-toggle::after {
        transform: rotate(180deg);
    }


}
